import PropTypes from 'prop-types';
import React from 'react';
import shortid from 'shortid';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import './default.scss';

class Offer extends React.Component {
    static propTypes = {
        image: PropTypes.string,
        headline: PropTypes.string,
        benefits: PropTypes.array,
        footnotes: PropTypes.array,
        onSelectOffer: PropTypes.func,
        redirectUrl: PropTypes.string.isRequired,
    };

    constructor(props) {
        super(props);

        this.state = {
            expanded: false,
        };

        this.toggleItem = this.toggleItem.bind(this);
    }

    toggleItem(e) {
        e.preventDefault();

        this.setState(prevState => ({
            expanded: !prevState.expanded,
        }));
    }

    render() {
        const {
            image,
            headline,
            benefits,
            footnotes,
            offerType,
            children,
            redirectUrl,
            pixel,
            onSelectOffer,
        } = this.props;
        const { expanded } = this.state;
        return (
            <table data-filter-set="top-10" className={`table table--unstripe table--offer box-shadow table--offer__${offerType}`}>
                <tbody>
                    <tr className="offer__overview">
                        <td>
                            <LazyLoadComponent>
                                { image ? <img alt="" onClick={this.toggleItem} style={{ cursor: 'pointer' }} src={image} /> : null }
                            </LazyLoadComponent>
                        </td>
                        <td>
                            <h5 className="offer--headline">{ headline }</h5>
                            <ul className="list">
                                { benefits.map(benefit => <li key={shortid.generate()}>{ benefit }</li>) }
                            </ul>

                            { footnotes instanceof Array
                                ? <ul className="offer__footnotes">{ footnotes.map(footnote => <li key={shortid.generate()}>{ footnote }</li>) }</ul>
                                : null }

                            <img alt="" style={{ maxWidth: '1px', maxHeight: '1px' }} src={pixel} />
                        </td>
                        <td className="offer__actions">
                            <a onClick={onSelectOffer} target="_blank" rel="noopener noreferrer" className="button button--success" href={redirectUrl}>Get Started</a>
                            <br />
                        </td>
                    </tr>
                    <tr className={`offer__learn-more ${!expanded ? '' : 'hidden'}`}>
                        <td colSpan="3">
                            <button className="offer__learn-more__expander" onClick={this.toggleItem}>Learn More ▼</button>
                        </td>
                    </tr>
                    <tr className={`offer__details ${!expanded ? 'hidden' : ''}`}>
                        <td colSpan="4">
                            <div className="offer__details__sections">
                                { children }
                            </div>
                        </td>
                    </tr>
                    { expanded
                        ? (
                            <tr className="offer__actions">
                                <td colSpan="3">
                                    <button className="offer__learn-more__expander" onClick={this.toggleItem}>Show Less ▲</button>
                                </td>
                            </tr>
                        )
                        : null }
                </tbody>
            </table>
        );
    }
}

export default Offer;
