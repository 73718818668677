import shortid from 'shortid';
import PropTypes from 'prop-types';
import React from 'react';
import { TableWrapper } from '@brandedholdings/react-components';
import Offer from '../Offer';

import './default.scss';

import { utils } from '@brandedholdings/react-components';
const { Log } = utils;

function upperCaseFirst(s) {
    return s.substr(0, 1).toUpperCase() + s.substr(1).toLowerCase();
}

export default class OfferList extends React.Component {
    static propTypes = {
        type: PropTypes.string,
        heading: PropTypes.string,
        offers: PropTypes.array.isRequired,
        showMore: PropTypes.string,
        limit: PropTypes.number,
        showMoreLimit: PropTypes.number,
        onSelectOffer: PropTypes.func.isRequired,
    };

    static defaultProps = {
        type: 'other',
        heading: false,
        offers: [],
        showMore: 'Show More Offers',
        limit: 2,
        showMoreLimit: 5
    };

    constructor(props) {
        super(props);

        this.state = {
            numVisible: props.limit,
            maxVisible: props.offers.length
        };
    };

    handleShowMore = e => {
        e.preventDefault();

        this.setState({
            numVisible: Math.min(this.state.maxVisible, this.state.numVisible + this.props.showMoreLimit)
        })
    };

    renderLearnMoreSection({ type, body }) {
        switch (type) {
            case 'paragraph':
                return <p>{ body }</p>;
            case 'key-value':
                return <ul className="list list--unbulleted">{ body.map(({ heading, body }) => <li key={shortid.generate()}><strong>{ heading }:</strong> { body }</li>) }</ul>;
            case 'bullets':
                return <ul className="list">{ body.map(bullet => <li key={shortid.generate()}>{ bullet }</li>) }</ul>;
            default:
                return <p>{ body }</p>;
        }
    }

    createOfferHandler = offer => {
        return (e) => {

            Log.toDataLayer({
                event: 'offer_click',
                offer_name: offer.body.offer_name
            });

            this.props.onSelectOffer(e, offer);
        };
    };

    render() {
        const { type, showMore, heading, offers } = this.props;
        
        const visibleOffers = offers.slice(0, this.state.numVisible);

        return visibleOffers.length ? <div className={"container--" + type}>
            { heading ? <h4>{ heading }</h4> : null }
            <TableWrapper className="results-list">
                { visibleOffers.map(offer => {
                    const {
                            image_url,
                            accupixel_url
                        } = offer;

                    let body = offer.body;

                    if (typeof body === 'string') {
                        try {
                            body = JSON.parse(body);
                        } catch (e) {
                            console.error('failed to render offer body', e.message, offer);
                            return null;
                        }
                    }

                    const {
                            offer_name,
                            cta_button_text,
                            features_footnotes,
                            rating,
                            interest_rate,
                            interest_rate_type,
                            learn_more
                        } = body;

                    let features = body.features;

                    if (typeof features[0] === 'object')
                        features = features[0].body;
                    
                    const extraProps = {};

                    if (type === 'card') {
                        extraProps.interestRate = upperCaseFirst(interest_rate || '');
                        extraProps.interestType = upperCaseFirst(interest_rate_type || '');
                    }

                    return <Offer
                        offerType={ type }
                        image={ image_url }
                        headline={ offer_name }
                        benefits={ features }
                        footnotes={ features_footnotes }
                        pixel={ accupixel_url }
                        key={ image_url }
                        rating={ rating }
                        ctaText={ cta_button_text }
                        redirectUrl={ offer.redirect_url }
                        onSelectOffer={ this.createOfferHandler(offer) }
                        { ...extraProps } >
                            { learn_more.map(section => {
                                return <div key={shortid.generate()}>
                                    { section.heading && <h4>{ section.heading }</h4> }
                                    { this.renderLearnMoreSection(section) }
                                </div>;
                            }) }
                    </Offer>;
                }) }
                { showMore && this.state.numVisible < this.state.maxVisible
                    ? <div className="text-align--center"><a href="/" onClick={ this.handleShowMore } className="button button--show-more">{ showMore }</a></div>
                    : null }
            </TableWrapper>
        </div> : null;
    }
};
