import PropTypes from 'prop-types';
import React from 'react';
import { connect } from 'react-redux';
import MobileDetect from 'mobile-detect';
import {
    Hero,
    Modal,
    SEO,
    utils,
    PegasusClient,
    PegasusPageView
} from '@brandedholdings/react-components';
import { home as pageMeta } from '../data/page_meta.yml';
import { siteMeta } from '../data/site_meta.yml';
import PageWrapper from '../components/DefaultPageWrapper';
import PagePost from '../models/PagePost';
import OfferList from '../components/OfferList';
import ResultsForm from '../components/ResultsForm';
import PrepopulatedFields from '../models/PrepopulatedFields';

const { Log } = utils;

const tokenize = (template, interpolations) => {
    if (template.type === 'string') {
        return template.value.replace(/\{\{\s*([^}\s]+)\s*\}\}/g, (_, token) => {
            return interpolations[token.trim()] || '';
        });
    }

    if (template.type === 'mapping') {
        const mapKey = template.value.replace(/\{\{\s*([^}\s]+)\s*\}\}/g, (_, token) => {
            return interpolations[token.trim()] || '';
        });

        if (!template.mapping || !(mapKey in template.mapping)) {
            console.error(`No mapping data found for ${template}`);
            return '';
        }

        return template.mapping[mapKey];
    }
};

class Results extends React.Component {
    static numInitialResults = 5;

    static numShowResults = 5;

    static propTypes = {
        ppc: PropTypes.bool.isRequired,
        hero: PropTypes.node.isRequired,
    };

    static defaultProps = {
        ppc: true,
        hero: (<Hero
                heading="We've found these&nbsp;offers"
                subheading="Custom tailored to your needs"
                actionText={ null }
                actionLink="#"
                heroModifiers="hero--arrow"
                actionModifiers={ null }
        />)
    };

    constructor(props) {
        super(props);

        const { offers } = this.props;
        this.offers = offers || {};

        if (offers && (typeof offers === 'object' && Object.keys(offers).length === 0) && typeof sessionStorage !== 'undefined') {
            const sessionOffers = sessionStorage.getItem('fm_offers');

            if (sessionOffers) {
                this.offers = JSON.parse(sessionOffers);
            } else {
                window.location = '/personal-loans/get-started/';
            }
        }

        this.state = {
            variant: 'default',
            conversionPixel: false,
            offerRedirect: false,
            urlParams: {}
        };

        if (typeof window !== 'undefined') {
            this.device = new MobileDetect(navigator.userAgent);
        }
    }

    getChildContext() {
        return { siteMeta, pageMeta };
    }

    componentDidMount() {
        const { offers } = this;


        if (offers) {
            Log.toDataLayer({
                event: 'results',
            });
        }

        if (window.OneSignal && typeof window.OneSignal.isOptedOut === 'function') {
            window.OneSignal.isOptedOut().then(isOptedOut => {
                if (isOptedOut) {
                    return;
                }

                window.OneSignal.isPushNotificationsEnabled().then(isEnabled => {
                    if (!isEnabled) {
                        window.OneSignal.showHttpPrompt();
                    }
                });
            });
        }
    }

    onModalFormSubmit = () => {
        const { activeOffer } = this.state;
        const { tracking_pixel } = activeOffer;

        this.setState({
            conversionPixel: tracking_pixel
        }, () => {
            if (!tracking_pixel) this.onConversionPixelLoad();
        });
    };

    openModal = (e, offer) => {
        e.preventDefault();

        if ('history' in window) window.history.pushState({}, '', `${document.location.search}#credit-report-step`);

        Log.toAnalyticsEvent('Offer', 'Select', `${offer.body.offer_name} - ${offer.banner_id}`, true);

        this.setState(
            {
                activeOffer: offer,
                offerRedirect: offer.redirect_url,
                offerTelNumber: offer.body.tel_number,
                urlParams: offer.body.url_params || {},
            },
            // remove to reactivate opt-in modal
            this.handleModal
        );

        // uncomment to reactivate opt-in modal
        // this._formModal.openModal();
    };

    onCloseModal = (e) => {
        if (e && e.preventDefault instanceof Function) e.preventDefault();
        this.onModalFormSubmit();
    };

    onConversionPixelLoad = () => {
        const { offerRedirect, offerTelNumber, urlParams } = this.state;
        const deviceIsPhone = !!this.device.phone();
        const { applicant } = this.props || {};

        for (const key in urlParams) {
            urlParams[key] = tokenize(urlParams[key], applicant);
        }

        this.setState({
            offerRedirect: false,
            offerTelNumber: false,
        }, () => {
            if (offerTelNumber && deviceIsPhone) {
                window.location.href = `tel://${offerTelNumber}`;
            } else if (offerRedirect) {
                const params = urlParams;
                const method = 'post';

                new PegasusPageView(siteMeta).postPageView();
                setTimeout(() => {
                    const form = document.createElement('form');
                    form.setAttribute('method', method);
                    form.setAttribute('action', offerRedirect);

                    for (const key in params) {
                        if (params.hasOwnProperty(key)) {
                            const hiddenField = document.createElement('input');
                            hiddenField.setAttribute('type', 'hidden');
                            hiddenField.setAttribute('name', key);
                            hiddenField.setAttribute('value', params[key]);
                            form.appendChild(hiddenField);
                        }
                    }

                    document.body.appendChild(form);
                    form.submit();
                }, 1200);
            }
        });
    };

    offerDeviceFilter = (offer) => {
        return offer.body.tel_number ? !!this.device.phone() : true;
    };

    handleModal() {
        if (PrepopulatedFields.isPrepopulated(['primaryPhone'])) {
            const fields = PrepopulatedFields.mapFields();
            const { primaryPhone } = fields;
            new PegasusClient(siteMeta).getIsSubscribed(primaryPhone, siteMeta.optInEntityId).then(
                (res) => {
                    if (res.data.is_subscribed === true) {
                        this.onModalFormSubmit();
                    } else {
                        this._formModal.openModal();
                    }
                }, (reason) => {
                    this._formModal.openModal();
                }
            );
        } else {
            console.warn('NO_PHONE_DATA');
            this._formModal.openModal();
        }
    }

    render() {
        const { ppc, hero } = this.props;
        const post = new PagePost(pageMeta, siteMeta);
        const { conversionPixel } = this.state;
        const { offers } = this;

        const featureOffers = ((offers && offers.featured) || []).filter(this.offerDeviceFilter);
        const loanOffers = ((offers && offers.loans) || []).filter(this.offerDeviceFilter);
        const otherLoanOffers = ((offers && offers['other-loans']) || []).filter(this.offerDeviceFilter);
        const creditCardOffers = ((offers && offers['credit-cards']) || []).filter(this.offerDeviceFilter);
        const otherOffers = ((offers && offers.other) || []).filter(this.offerDeviceFilter);

        return (
            <PageWrapper ppc={ppc}>
                <SEO post={post} />
                { hero }
                <div className="layout layout--narrow">
                    <div className="layout-content">
                        <OfferList
                            type="featured"
                            heading="Featured Offer"
                            showMore="Show More Loans"
                            offers={featureOffers}
                            onSelectOffer={this.openModal}
                        />

                        <OfferList
                            type="loan"
                            heading="Loans You Might Be Interested In"
                            showMore="Show More Loans"
                            offers={loanOffers}
                            onSelectOffer={this.openModal}
                        />

                        <OfferList
                            type="card"
                            heading="Credit Cards You Might Be Interested In"
                            showMore="Show More Credit Cards"
                            offers={creditCardOffers}
                            onSelectOffer={this.openModal}
                        />

                        <OfferList
                            type="loan-other"
                            heading="Other Loans You Might Be Interested In"
                            showMore="Show More Loans"
                            offers={otherLoanOffers}
                            onSelectOffer={this.openModal}
                        />

                        <OfferList
                            type="other"
                            heading="Other Offers You Might Be Interested In"
                            showMore="Show More Offers"
                            offers={otherOffers}
                            onSelectOffer={this.openModal}
                        />
                    </div>

                    { conversionPixel
                        ? <img src={conversionPixel} onLoad={this.onConversionPixelLoad} alt="" />
                        : null }

                    <Modal
                        heading={false}
                        triggerOnClick
                        className="form-modal"
                        onClose={this.onCloseModal}
                        ref={ref => this._formModal = ref}
                    >
                        <ResultsForm
                            onSubmit={this.onModalFormSubmit}
                            onCloseModal={this.onCloseModal}
                        />
                    </Modal>
                </div>
            </PageWrapper>
        );
    }
}

Results.childContextTypes = {
    siteMeta: PropTypes.object,
    pageMeta: PropTypes.object,
};

function mapStateToProps(state) {
    return {
        offers: state.offers,
        applicant: state.applicantFields,
    };
}

export default connect(mapStateToProps)(Results);
